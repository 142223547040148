@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="range"]::-webkit-slider-thumb {
    @apply w-[18px] h-[18px] bg-red-500;
  }
  input[type="range"]::-moz-range-thumb {
    @apply w-[18px] h-[18px] bg-red-500;
  }
  input[type="range"]::-ms-thumb {
    @apply w-[18px] h-[18px] bg-red-500;
  }
  
  body {
    font-size: 18px;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    margin-top: 1rem  !important;
    margin-bottom: 2rem  !important;
  }
  
  @media (min-width: 768px) {
    body {
      margin-top: 2rem  !important;
    }
  }
}


.container {
  @apply m-auto px-3;
  max-width: 425px;
}
.contentText p {
  @apply mb-4;
  font-size: 18px;
}
.w-500px {
  max-width: 700px;
}
p {
  @apply py-2;
}
.text li {
  @apply float-none clear-both;
  list-style-type: circle;
  padding-left: 1rem;
  margin-left: 2rem;
}
p ul li {
}
.btn {
  @apply py-2 px-5 rounded-lg text-white my-1;
  background-color: #038c3e;
}

div.field label {
  @apply w-full clear-both block pb-1 pt-4;
}
div.field input,
div.field select {
  @apply rounded-lg border border-gray-300;
}
.open-sans-300 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 300;
}
